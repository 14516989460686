a { color: var(--primary) !important; }

.mat-mdc-raised-button, .mat-mdc-stroked-button {
    background-color: var(--primary) !important;
    border-radius: var(--input-border-radius) !important;
}

.mat-mdc-raised-button:disabled {
    background-color: var(--primary-disabled) !important;
}

.mat-mdc-button:not(:disabled) {
    color: var(--primary) !important;
}

.mat-mdc-outlined-button {
    border-radius: var(--input-border-radius) !important;
}

.mat-mdc-outlined-button:not(:disabled) {
    color: var(--primary) !important;
}

.mat-mdc-outlined-button:not(:disabled) {
    border-color: var(--primary) !important;
}

.mdc-button--outlined .mdc-button__ripple {
    border-color: var(--primary) !important;
}

.mat-mdc-outlined-button.mat-primary {
    --mat-mdc-button-persistent-ripple-color: var(--primary) !important;
} 

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: var(--primary) !important;
}

.mdc-text-field--filled .mdc-line-ripple:after {
    border-bottom-color: var(--primary) !important;
}

input { caret-color: var(--primary) !important; }

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    transition: stroke 0.75s;
    stroke: var(--spinner-color) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after {
    color: var(--primary) !important;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: var(--primary) !important;
}

.mat-icon.mat-primary {
    color: var(--primary) !important;
}

.mdc-line-ripple {

    &::before {
        border-bottom-width: 0px !important;
    }

    &:focus:before {
        border-bottom-width: 0px !important;
    }

}

.mdc-text-field {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
