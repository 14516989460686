@import "./material.scss";
@import "./theme.scss";

:root {
    --primary: #3870ff;
    --primary-disabled: #c3d4ff;
    --input-border-radius: 8px;
    --input-box-shadow: var(--primary) 0px 1px 5px;
    --spinner-color: #E3E7EB;
    --background-color: #f5f8ff;
}

$button-height: 55px;

html,
body {
    height: 100%;

}

html {
    font-size: 0.65em;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    background-color: var(--background-color);
    transition: background-color 1s;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #10151A;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

h1,
.h1 {
    font-size: 3rem;
}

h2,
.h2 {
    font-size: 2.5rem;
}


h3,
.h3 {
    font-size: 2rem;
}

p {
    font-size: 1.8rem;
}

.pdf-viewer .ng2-pdf-viewer-container .pdfViewer .page {
    padding: 0px !important;
}

.ng2-pdf-viewer-container {
    overflow-x: hidden !important;
}

/* GLOBAL */

.cdk-global-scrollblock {
    overflow-y: hidden;
}

/* TEXT */

.title {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.123em;
}

.list-title {
    color: black;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold !important;
    font-size: 1.25em !important;
}

.primary {
    color: var(--primary);
}

/* MATERIAL ICON */

mat-icon {
    transform: scale(1.25);
}

/* MATERIAL BUTTON */

.mat-mdc-raised-button,
.mat-mdc-stroked-button {

    height: $button-height !important;
    box-shadow: none !important;
    font-weight: 700;
    border-radius: var(--input-border-radius);
    background-color: var(--primary) !important;

    &:disabled {
        color: white !important;
        background-color: var(--primary-disabled) !important;
    }

}

.mdc-button--outlined .mdc-button__ripple {
    border-color: var(--primary-disabled) !important;
}

.mat-mdc-outlined-button {
    height: $button-height !important;
}

/*FORMS*/

.custom-disable-input {
    opacity: 1.0 !important;
    color: #585B5F !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #F2F6FA !important;
    border: 1px solid #E3E7EB;
    border-radius: var(--input-border-radius) !important;
    font-size: 1.8rem;
    height: 56px;
}

.mdc-text-field--filled .mdc-line-ripple:after {
    border: none !important;
}

.mdc-text-field--focused {
    border: 1px solid var(--primary) !important;
    transition: box-shadow 0.2s;
    box-shadow: var(--input-box-shadow) !important;
    background-color: #E8ECF0 !important;
}

country-select .dropdown-open .mdc-text-field--focused,
select-bank-page .mdc-text-field--focused {
    box-shadow: none !important;
    border: none !important;
}

country-item li.selected {
    color: var(--primary) !important;
}

.mat-mdc-button:not(:disabled) {
    color: var(--primary) !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 14px;
}

/*DESPLEGABLE PREFIJOS*/

.dropdown-collection .dropdown-list mat-form-field {
    width: 100% !important;
    margin: 0px !important;

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: #FFF !important;
        border: none;
        border-radius: 0px !important;
        font-size: 1.8rem;
        padding: 0px;
        border-bottom: 1px solid #E3E7EB;
    }
}

/* DIALOG */

.mat-mdc-dialog-content {
    max-height: none !important;
    padding: 0 !important;
}

/* LIST ITEM */

.mdc-list-item__primary-text {
    color: black !important;
    line-height: 1.25em !important;
}

/*PERMISIONS*/

app-permissions .border .d-flex img {
    margin-right: 24px;
}

app-permissions .border.rounded {
    padding: 24px 32px;
}

app-permissions .border .d-flex span {
    font-size: 0.9em;
    color: #10151A;
}

app-permissions .txt-identificate-con {
    font-size: 0.8em;
    color: #585B5F;
    text-align: center;
    line-height: 16px;
}

/*Verify phone*/

verify-phone-page .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
}

/*Error message*/

.cdk-overlay-pane .mdc-dialog__container h2 {
    font-size: 2.2rem !important;
    margin-bottom: 1rem !important;

}

.cdk-overlay-pane .mdc-dialog__container img {
    width: 60px;
    margin-bottom: 20px !important;
}

.cdk-overlay-pane .mdc-dialog__container p {
    font-size: 1.6rem !important;
}


/*Bank consents*/

.fields {
    border-radius: var(--input-border-radius) !important;
}

.operation .fields {
    // padding-top: 2.5rem !important;
}

.operation .fields .mat-mdc-form-field-icon-prefix {
    padding: 16px;
}

.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-icon-prefix {
    opacity: 0.5;
}

.mat-mdc-form-field-type-mat-select .mat-mdc-select-placeholder {
    font-size: 0.8em;
}

.operation .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: #F8FBFC !important;
    border: 1px solid #E3E7EB !important;
    border-radius: var(--input-border-radius) !important;
    height: 60px;
}

.operation .mat-mdc-select-value-text {
    font-size: 0.8em;
}

.operation .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: inherit !important;
    display: flex;
    align-content: center;
    align-items: center;
    padding-right: 16px !important;
}

.operation .mat-mdc-form-field-subscript-wrapper {
    height: 4px;
}

.operation .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 32px;
}

.operation .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    height: 60px;
}

/* SCROLLBAR */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {

    background: #eee;
    border-radius: 10px;

    &:hover {
        background: #ddd;
    }

}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--spinner-color) !important;
    transition: stroke 0.5s;
}

/* CONTAINER */

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.page {
    padding: 40px;
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}

// Countries dialog

.countries-dialog {
    max-height: 80vh;
    height: 70vh;
    width: 480px;
}

@media only screen and (max-height: 900px) {
    .countries-dialog {
        height: 90vh;
    }
}

@media only screen and (max-height: 770px) {
    .countries-dialog {
        height: 95vh;
    }
}

@media only screen and (max-width: 600px) {
    .countries-dialog {
        width: 90vw;
    }
}

@media only screen and (max-width: 480px) {
    .countries-dialog {
        width: 100%;
        height: 100vh;
        box-shadow: none;
    }
}
